import React, { useState } from 'react'
import { Button, Form, Row, Col } from 'react-bootstrap';
import axios from 'axios';

const Adminform = () => {
  
  const [No, setNo] = useState("");
  const [date, setdate] = useState("");
  const [subject, setsubject] = useState("");
  const [Place, setPlace] = useState("");
  const [Name, setName] = useState("");
  const [co, setCo] = useState("");
  const [sahco, setSahco] = useState("");
  const [university,setUniversity] = useState("");
  const [Year, setYear] = useState("");
  const [position, setPosition] = useState("");
  const [amount,setAmount] = useState("");
  const [Award, setAward] = useState("");
  const [mobileNo,setMobileNo] = useState("");
  const [email, setEmail] = useState("");
  const [notice,setnotice] = useState("");
  const [letter,setletter] = useState("");
  const [address,setAddress] = useState("");


  const onSubmitHandler = async (e) => {
    e.preventDefault();
    try {
      const seminardata = {
        No,
        date,
        subject,
        Place,
      };
      await axios.post("https://api.ppag.co.in/adminform/seminar", seminardata);
      alert('Data entered Successfully');
    }
    catch (error) {
      console.log(error.message);
    }
  }

  const onSubmitHandlerPetron = async (e) => {
    e.preventDefault();
    try {
      const petrondata = {
        No,
        Name,
        Place,
      };
      await axios.post("https://api.ppag.co.in/adminform/petron", petrondata);
      alert('Data entered Successfully');
    }
    catch (error) {
      console.log(error.message);
    }
  }

  const onSubmitHandlerShalakar = async (e) =>{
    e.preventDefault()
    try{
      const shalakardata ={
        No,
        Name,
        Place,
      }
      await axios.post("https://api.ppag.co.in/adminform/shalakar",shalakardata)
      alert('Data entered Successfully');
    }
    catch(err){
      console.log(err.message);
    }
  }

  const onSubmitHandlerZonal = async (e) =>{
    e.preventDefault()
    try {
      const zonaldata ={
        No,
        co,
        subject,
        sahco,
        university,
      }
      await axios.post("https://api.ppag.co.in/adminform/zonal", zonaldata)
      alert('Data entered Successfully');
    } 
    catch (error) {
      console.log(error.message)
    }
  }

  const onSubmitHandlerZonal1 =async (e) =>{
    e.preventDefault();
    try {
      const zonal1data ={
        Place,
        Name,
        Year,
      }
      await axios.post("https://api.ppag.co.in/adminform/zonal1",zonal1data)
      alert('Data entered Successfully');
    } catch (error) {
      console.log(error.message)
    }
  }

  const onSubmitHandlerKarobari = async (e) =>{
    e.preventDefault()
    try {
      const karobaridata ={
        No,
        Name,
        subject,
      }
      await axios.post("https://api.ppag.co.in/adminform/karobari", karobaridata)
      alert('Data entered Successfully');
    } catch (error) {
      console.log(error.message)
    }
  }

  const onSubmitHandlerHodedaro = async (e) =>{
    e.preventDefault()
    try {
      const hodedaroData = {
        position,
        Name,
        Year,
      }
      await axios.post("https://api.ppag.co.in/adminform/hodedaro",hodedaroData)
      alert('Data entered Successfully');
    } catch (error) {
      console.log(error.message)
    }
  }

  const onSubmitHandlerAward = async (e) =>{
    e.preventDefault()
    try {
      const awarddata ={
        No,
        Name,
        amount,
        Year,
        Award,
      }
      await axios.post("https://api.ppag.co.in/adminform/award",awarddata)
      alert('Data entered Successfully');
    } catch (error) {
      console.log(error.message)
    }
  }

  const onSubmitHandlerAajivan = async (e) =>{
    e.preventDefault()
    try {
      const aajivanData={
        No,
        Name,
        mobileNo,
        email,
        address,
      }
      await axios.post("https://api.ppag.co.in/adminform/aajivan", aajivanData)
      alert('Data entered Successfully');
    } catch (error) {
      console.log(error.message)
    }
  }


  const onSubmitHandlerNotice = async (e) =>{
    e.preventDefault()
    try {
      const noticeData={
        notice
      }
      await axios.post("https://api.ppag.co.in/adminform/notice", noticeData)
      alert('Data entered Successfully');
    } catch (error) {
      console.log(error.message)
    }
  }

  const onSubmitHandlerletter = async (e) =>{
    e.preventDefault()
    try {
      const letterData={
        letter
      }
      await axios.post("https://api.ppag.co.in/adminform/letterpad", letterData)
      alert('Data entered Successfully');
    } catch (error) {
      console.log(error.message)
    }
  }


  return (
    <div className='container'>
      <h3>
        Enter the Details
      </h3>
      <h3>
        સેમિનાર
      </h3>
      <Form>
        <Row className="mb-3 text">
          <Form.Group as={Col} controlId="number">
            <Form.Label>અ.નં.</Form.Label>
            <Form.Control
              type="text"
              placeholder="અ.નં."
              value={No}
              onChange={(e) => setNo(e.target.value)}
              maxLength={10} />
          </Form.Group>
          <Form.Group as={Col} controlId="formGriddate">
            <Form.Label>તારીખ</Form.Label>
            <Form.Control
              type="date"
              placeholder="તારીખ"
            value={date}
            onChange={(e) => setdate(e.target.value)}
            />
          </Form.Group>
          <Form.Group as={Col} controlId="formGridPassword">
            <Form.Label>વિષય</Form.Label>
            <Form.Control
              type="text"
              placeholder="વિષય"
              value={subject}
              onChange={(e) => setsubject(e.target.value)}
            />
          </Form.Group>
          <Form.Group as={Col} controlId="formGridPassword">
            <Form.Label>સ્થળ</Form.Label>
            <Form.Control
              type="text"
              placeholder="સ્થળ"
              value={Place}
              onChange={(e) => setPlace(e.target.value)}
            />
          </Form.Group>
        </Row>
        <Button 
        type="submit" 
        style={{ backgroundColor: "black", borderColor: "black" }}
        variant='primary'
        onClick={onSubmitHandler}
        >Submit</Button>
      </Form>
      <h3>
        પેટ્રોન સભ્યો
      </h3>
      <Form>
        <Row className="mb-3 text">
          <Form.Group as={Col} controlId="number">
            <Form.Label>ક્રમ</Form.Label>
            <Form.Control 
            type="text" 
            placeholder="ક્રમ" 
            maxLength={10} 
            value={No}
            onChange={(e)=> setNo(e.target.value)}
            />
          </Form.Group>
          <Form.Group as={Col} controlId="formGridPassword">
            <Form.Label>નામ</Form.Label>
            <Form.Control 
            type="text" 
            placeholder="નામ"
            value={Name}
            onChange={(e)=> setName(e.target.value)}
            />
          </Form.Group>
          <Form.Group as={Col} controlId="formGridPassword">
            <Form.Label>સ્થળ</Form.Label>
            <Form.Control 
            type="text" 
            placeholder="સ્થળ"
            value={Place}
            onChange={(e)=> setPlace(e.target.value)}
            />
          </Form.Group>
        </Row>
        <Button 
        type="submit" 
        style={{ backgroundColor: "black", borderColor: "black" }}
        variant='primary'
        onClick={onSubmitHandlerPetron}
        >Submit</Button>
      </Form>
      <h3>
        સલાહકાર સભ્યો
      </h3>
      <Form>
        <Row className="mb-3 text">
          <Form.Group as={Col} controlId="number">
            <Form.Label>ક્રમ</Form.Label>
            <Form.Control 
            type="text" 
            placeholder="ક્રમ" 
            maxLength={10} 
            value={No}
            onChange={(e)=> setNo(e.target.value)}
            />
          </Form.Group>
          <Form.Group as={Col} controlId="formGridPassword">
            <Form.Label>નામ</Form.Label>
            <Form.Control 
            type="text" 
            placeholder="નામ"
            value={Name}
            onChange={(e)=> setName(e.target.value)}
            />
          </Form.Group>
          <Form.Group as={Col} controlId="formGridPassword">
            <Form.Label>સ્થળ</Form.Label>
            <Form.Control 
            type="text" 
            placeholder="સ્થળ"
            value={Place}
            onChange={(e)=> setPlace(e.target.value)}
            />
          </Form.Group>
        </Row>
        <Button 
        type="submit" 
        style={{ backgroundColor: "black", borderColor: "black" }}
        variant='primary'
        onClick={onSubmitHandlerShalakar}
        >Submit</Button>
      </Form>
      <h3>
        ઝોનલ અને સહ ઝોનલ કોર્ડીનેટર
      </h3>
      <Form>
        <Row className="mb-3 text">
          <Form.Group as={Col} controlId="number">
            <Form.Label>ક્રમ</Form.Label>
            <Form.Control 
            type="text" 
            placeholder="ક્રમ" 
            maxLength={10}
            value={No}
            onChange={(e)=>setNo(e.target.value)}
            />
          </Form.Group>
          <Form.Group as={Col} controlId="formGridPassword">
            <Form.Label>કો-ઓર્ડિનટર</Form.Label>
            <Form.Control 
            type="text" 
            placeholder="કો-ઓર્ડિનટર"
            value={co}
            onChange={(e)=>setCo(e.target.value)}
            />
          </Form.Group>
          <Form.Group as={Col} controlId="formGridPassword">
            <Form.Label>વિભાગ</Form.Label>
            <Form.Control 
            type="text" 
            placeholder="વિભાગ"
            value={subject}
            onChange={(e)=> setsubject(e.target.value)}
            />
          </Form.Group>
          <Form.Group as={Col} controlId="formGridPassword">
            <Form.Label>સહ ઝોનલ કો-ઓર્ડિનટર</Form.Label>
            <Form.Control 
            type="text" 
            placeholder="સહ ઝોનલ કો-ઓર્ડિનટર"
            value={sahco}
            onChange={(e)=> setSahco(e.target.value)}
            />
          </Form.Group>
          <Form.Group as={Col} controlId="formGridPassword">
            <Form.Label>યુનિવર્સિટી</Form.Label>
            <Form.Control 
            type="text" 
            placeholder="યુનિવર્સિટી"
            value={university}
            onChange={(e)=> setUniversity(e.target.value)}
            />
          </Form.Group>
        </Row>
        <Button 
        type="submit" 
        style={{ backgroundColor: "black", borderColor: "black" }}
        onClick={onSubmitHandlerZonal}
        >Submit</Button>
      </Form>
      <h3>
        પીપીએજીના ઝોનલ કો-ઓર્ડિનટર
      </h3>
      <Form>
        <Row className="mb-3 text">
          <Form.Group as={Col} controlId="number">
            <Form.Label>સ્થળ</Form.Label>
            <Form.Control 
            type="text" 
            placeholder="સ્થળ"
            value={Place}
            onChange={(e)=> setPlace(e.target.value)}
            />
          </Form.Group>
          <Form.Group as={Col} controlId="formGridPassword">
            <Form.Label>કો-ઓર્ડિનટર</Form.Label>
            <Form.Control 
            type="text" 
            placeholder="કો-ઓર્ડિનટર"
            value={Name}
            onChange={(e)=> setName(e.target.value)}
            />
          </Form.Group>
          <Form.Group as={Col} controlId="formGridPassword">
            <Form.Label>વર્ષ</Form.Label>
            <Form.Control 
            type="text" 
            placeholder="વર્ષ"
            value={Year}
            onChange={(e)=> setYear(e.target.value)}
            />
          </Form.Group>
        </Row>
        <Button 
        type="submit" 
        style={{ backgroundColor: "black", borderColor: "black" }}
        onClick={onSubmitHandlerZonal1}
        >Submit</Button>
      </Form>
      <h3>
        કારોબારીના સભ્યો
      </h3>
      <Form>
        <Row className="mb-3 text">
          <Form.Group as={Col} controlId="number">
            <Form.Label>ક્રમ</Form.Label>
            <Form.Control 
            type="text" 
            placeholder="ક્રમ" 
            value={No}
            onChange={(e)=>setNo(e.target.value)}
            />
          </Form.Group>
          <Form.Group as={Col} controlId="formGridPassword">
            <Form.Label>નામ</Form.Label>
            <Form.Control 
            type="text" 
            placeholder="નામ"
            value={Name}
            onChange={(e)=>setName(e.target.value)}
            />
          </Form.Group>
          <Form.Group as={Col} controlId="formGridPassword">
            <Form.Label>હોદ્દો</Form.Label>
            <Form.Control 
            type="text" 
            placeholder="હોદ્દો"
            value={subject}
            onChange={(e)=> setsubject(e.target.value)}
            />
          </Form.Group>
        </Row>
        <Button 
        type="submit" 
        style={{ backgroundColor: "black", borderColor: "black" }}
        onClick={onSubmitHandlerKarobari}
        >Submit</Button>
      </Form>
      <h3>
        પીપીએજી ના હોદ્દેદારો
      </h3>
      <Form>
        <Row className="mb-3 text">
          <Form.Group as={Col} controlId="number">
            <Form.Label>સ્થાન</Form.Label>
            <Form.Control 
            type="text" 
            placeholder="સ્થાન"
            value={position}
            onChange={(e)=>setPosition(e.target.value)}
            />
          </Form.Group>
          <Form.Group as={Col} controlId="formGridPassword">
            <Form.Label>નામ</Form.Label>
            <Form.Control 
            type="text" 
            placeholder="નામ"
            value={Name}
            onChange={(e)=> setName(e.target.value)}
            />
          </Form.Group>
          <Form.Group as={Col} controlId="formGridPassword">
            <Form.Label>વર્ષ</Form.Label>
            <Form.Control 
            type="text" 
            placeholder="વર્ષ" 
            value={Year}
            onChange={(e)=> setYear(e.target.value)}
            />
          </Form.Group>
        </Row>
        <Button 
        type="submit" 
        style={{ backgroundColor: "black", borderColor: "black" }}
        onClick={onSubmitHandlerHodedaro}
        >Submit</Button>
      </Form>
      <h3>
        વિવિધ એવોર્ડ માટે મળેલ અનુદાન
      </h3>
      <Form>
        <Row className="mb-3 text">
          <Form.Group as={Col} controlId="number">
            <Form.Label>ક્રમ</Form.Label>
            <Form.Control 
            type="text" 
            placeholder="ક્રમ" 
            value={No}
            onChange={(e)=> setNo(e.target.value)}
            />
          </Form.Group>
          <Form.Group as={Col} controlId="formGridPassword">
            <Form.Label>નામ</Form.Label>
            <Form.Control 
            type="text" 
            placeholder="નામ"
            value={Name}
            onChange={(e)=> setName(e.target.value)}
            />
          </Form.Group>
          <Form.Group as={Col} controlId="formGridPassword">
            <Form.Label>અનુદાનની રકમ(રૂ.)</Form.Label>
            <Form.Control 
            type="text" 
            placeholder="અનુદાનની રકમ(રૂ.)"
            value={amount}
            onChange={(e)=> setAmount(e.target.value)}
            />
          </Form.Group>
          <Form.Group as={Col} controlId="formGridPassword">
            <Form.Label>વર્ષ</Form.Label>
            <Form.Control 
            type="text" 
            placeholder="વર્ષ"
            value={Year}
            onChange={(e)=> setYear(e.target.value)}
            />
          </Form.Group>
          <Form.Group as={Col} controlId="formGridPassword">
            <Form.Label>એવોર્ડ</Form.Label>
            <Form.Control 
            type="text" 
            placeholder="એવોર્ડ"
            value={Award}
            onChange={(e)=> setAward(e.target.value)}
            />
          </Form.Group>
        </Row>
        <Button 
        type="submit" 
        style={{ backgroundColor: "black", borderColor: "black" }}
        onClick={onSubmitHandlerAward}
        >Submit</Button>
      </Form>
      <h3>
        આજીવન સભ્યો
      </h3>
      <Form>
        <Row className="mb-3 text">
          <Form.Group as={Col} controlId="number">
            <Form.Label>ક્રમ</Form.Label>
            <Form.Control 
            type="text" 
            placeholder="ક્રમ"
            value={No}
            onChange={(e)=>setNo(e.target.value)}
            />
          </Form.Group>
          <Form.Group as={Col} controlId="formGridPassword">
            <Form.Label>નામ</Form.Label>
            <Form.Control 
            type="text" 
            placeholder="નામ"
            value={Name}
            onChange={(e)=>setName(e.target.value)}
            />
          </Form.Group>
          <Form.Group as={Col} controlId="formGridPassword">
            <Form.Label>મોબાઈલ નો.</Form.Label>
            <Form.Control 
            type="number" 
            placeholder="મોબાઈલ નો."
            value={mobileNo}
            onChange={(e)=>setMobileNo(e.target.value)}
            />
          </Form.Group>
          <Form.Group as={Col} controlId="formGridPassword">
            <Form.Label>ઈ-મેલ</Form.Label>
            <Form.Control 
            type="text" 
            placeholder="ઈ-મેલ"
            value={email}
            onChange={(e)=>setEmail(e.target.value)}
            />
          </Form.Group>
          <Form.Group as={Col} controlId="formGridPassword">
            <Form.Label>સરનામું</Form.Label>
            <Form.Control 
            type="text" 
            placeholder="રસીદ નંબર"
            value={address}
            onChange={(e)=>setAddress(e.target.value)}
            />
          </Form.Group>
        </Row>
        <Button 
        type="submit" 
        style={{ backgroundColor: "black", borderColor: "black" }}
        onClick={onSubmitHandlerAajivan}
        >Submit</Button>
      </Form>
      <h3>
      નોટિસ
      </h3>
      <Form>
        <Row className="mb-3 text">
          <Form.Group as={Col} controlId="number">
            <Form.Label>નોટિસ</Form.Label>
            <Form.Control 
            type="text" 
            placeholder="નોટિસ"
            value={notice}
            onChange={(e)=>setnotice(e.target.value)}
            />
          </Form.Group>
        </Row>
        <Button 
        type="submit" 
        style={{ backgroundColor: "black", borderColor: "black" }}
        onClick={onSubmitHandlerNotice}
        >Submit</Button>
      </Form>
      <h3>
      લેટરપેડ
      </h3>
      <Form>
        <Row className="mb-3 text">
          <Form.Group as={Col} controlId="number">
            <Form.Label>લેટરપેડ</Form.Label>
            <Form.Control 
            type="text" 
            placeholder="લેટરપેડ"
            value={letter}
            onChange={(e)=>setletter(e.target.value)}
            />
          </Form.Group>
        </Row>
        <Button 
        type="submit" 
        style={{ backgroundColor: "black", borderColor: "black" }}
        onClick={onSubmitHandlerletter}
        >Submit</Button>
      </Form>
    </div>
  )
}

export default Adminform