import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table'
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css'
import './Sabhyo.css'
const Seminar =() =>{
	
	const [seminar,setseminar] = useState([]);

	const loadseminar = async () =>{
		try {
			const response = await axios.get("https://api.ppag.co.in/seminar");
			console.log(response.data);
			setseminar(response.data)
		} catch (error) {
			console.log(error.message)
		}
	} 
	useEffect(() => loadseminar(),[])

	return(
		<div  style={{textAlign:"center", width:"98%"}}>
			<h2 style={{'color':'#162655', 'fontSize':40}}>પીપીએજી દ્વારા આયોજીત કરવામાં આવેલા પરિસંવાદોની યાદી </h2>
			<Table>
				<Thead>
					<Tr>
                    <Th style={{'textAlign':'left'}}>અ.નં. </Th>
					<Th style={{'textAlign':'left'}}>તારીખ </Th>
					<Th style={{'textAlign':'left'}}>વિષય </Th>
					<Th style={{'textAlign':'left'}}>સ્થળ</Th>
					</Tr>
				</Thead>
				<Tbody>
				{seminar.map(item =>(
						<Tr key={item._id}>
							<Td style={{'textAlign':'left'}}>{item.No}</Td>
							<Td style={{'textAlign':'left'}}>{item.date}</Td>
							<Td style={{'textAlign':'left'}}>{item.subject}</Td>
							<Td style={{'textAlign':'left'}}>{item.Place}</Td>
						</Tr>
					))}
				</Tbody>
			</Table>
		</div>
	)
}

export default Seminar;