import React, { useState } from "react";
import { Form, Row, Col, Button } from "react-bootstrap";
import axios from "axios";
import { useHistory } from "react-router-dom";

const SignupForm = () => {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  let history = useHistory();

  const onSubmitHandler = async (e) => {
    e.preventDefault();
    try {
      const data = {
        firstName,
        lastName,
        email,
        password,
      };

      //console.log(data);
      await axios.post("https://api.ppag.co.in/signup", data);
      history.push("/admin");
      alert('Sign Up Successfull')
    } catch (error) {
      console.log(error.message);
    }
  };

  return (
    <div className="container">
        <h1 className="my-3" style={{ color: "white" }}>
          Register
        </h1>
        <Row>
          <Col md={6}>
            <Form onSubmit={onSubmitHandler}>
              <Form.Group controlId="formFirstName">
                <Form.Label style={{ color: "white" }}>First Name</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter first name"
                  value={firstName}
                  onChange={(e) => setFirstName(e.target.value)}
                />
              </Form.Group>

              <Form.Group controlId="formLastName">
                <Form.Label style={{ color: "white" }}>Last Name</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter last name"
                  value={lastName}
                  onChange={(e) => setLastName(e.target.value)}
                />
              </Form.Group>

              <Form.Group controlId="formBasicEmail">
                <Form.Label style={{ color: "white" }}>
                  Email address
                </Form.Label>
                <Form.Control
                  type="email"
                  placeholder="Enter email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </Form.Group>

              <Form.Group controlId="formBasicPassword">
                <Form.Label style={{ color: "white" }}>Password</Form.Label>
                <Form.Control
                  type="password"
                  placeholder="Password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
              </Form.Group>
              <Button
                variant="primary"
                type="submit"
                style={{
                  backgroundColor: "#328cc1",
                  borderColor: "white",
                  marginTop: "10px",
                }}
              >
                Submit
              </Button>
            </Form>
          </Col>
        </Row>
    </div>
  );
};

export default SignupForm;