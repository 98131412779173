import React, { useEffect, useState } from 'react'
import './Home.css'
import Marquee from "react-fast-marquee";
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css'
import About from '../About/About';
import { Link } from "react-router-dom"
import SimpleImageSlider from "react-simple-image-slider";
import Card from 'react-bootstrap/Card';
import ListGroup from 'react-bootstrap/ListGroup';

import pdf from './pdf/AssociateScientistAward2022.pdf'
import BestresearchworkdoneStudent from './pdf/Best research work done by Ph.D. Student.pdf'
import studentMSC from './pdf/Best research work done by M.Sc. (Agri.) student.pdf'
import Effective from './pdf/Effective Transfer of Plant Protection Technologies to the Farmers.pdf'
import FarmernaturalAward from './pdf/Farmer Award - Natural Farming.pdf'
import Innovative from './pdf/Innovation of New Pesticide.pdf'
import Outstanding from './pdf/Outstanding Research Work done in the Field of Bio-Pesticides by SAU’s Scientist.pdf'
import young from './pdf/Young Scientist Award-2022.pdf'
import FarmerAward from './pdf/Farmer Award.pdf'
import axios from 'axios';

const images = [
    { url: "images/1.jpg" },
    { url: "images/9.jpg" },
    { url: "images/3.jpg" },
    { url: "images/171.jpg" },
    { url: "images/151.jpg" },
    { url: "images/161.jpg" },
    { url: "images/7.jpg" },
];




const Home = () => {
const [notice,setnotice]= useState([]);
const [letterpad,setletterpad]= useState([]);

const loadnotice = async () =>{
    try {
        const response = await axios.get("https://api.ppag.co.in/notice");
        console.log(response.data);
        setnotice(response.data)
    } catch (error) {
        console.log(error.message)
    }
} 
const loadletterpad = async () =>{
    try {
        const response = await axios.get("https://api.ppag.co.in/letterpad");
        console.log(response.data);
        setletterpad(response.data)
    } catch (error) {
        console.log(error.message)
    }
} 

useEffect(()=>loadnotice(),[])
useEffect(()=> loadletterpad(),[])



    return (
        <div>
            <div className="icon-circle pink" style={{ "background": '#9ac2f9', 'color': 'white' }} >
                <Marquee behavior="scroll" scrolldelay="500" pauseOnHover='true' gradientWidth='150' style={{ fontWeight: 500, fontSize: 20, 'color': 'black' }} >
                    ●↣ <Link to={pdf} target="_blank" style={{ 'color': 'black' }}> Association scientist award</Link>
                    ●↣ <Link to={BestresearchworkdoneStudent} target="_blank" style={{ 'color': 'black' }} >Best research workdone by PHD Student</Link>
                    ●↣ <Link to={studentMSC} target="_blank" style={{ 'color': 'black' }}> Best research workdone by M.Sc.(Agri.)student</Link>
                    ●↣ <Link to={Effective} target="_blank" style={{ 'color': 'black' }} >Effective transfer of plant protection technologies to the farmers</Link>
                    ●↣ <Link to={FarmernaturalAward} target="_blank" style={{ 'color': 'black' }}> Farmer Award-Natural Farming</Link>
                    ●↣ <Link to={FarmerAward} target="_blank" style={{ 'color': 'black' }}> Farmer Award Farming</Link>
                    ●↣ <Link to={Innovative} target="_blank" style={{ 'color': 'black' }} >Innovation of new pesticide</Link>
                    ●↣ <Link to={Outstanding} target="_blank" style={{ 'color': 'black' }} > Outstanding research work done in the field of bio-pesticides by SAU’s scientist</Link>
                    ●↣ <Link to={young} target="_blank" style={{ 'color': 'black' }} >YOUNG SCIENTIST AWARDS</Link>
                </Marquee>
            </div>
            <section>
                <div className='row'>
                    <div className='col-md-9'>
                        <SimpleImageSlider
                            width={1500}
                            height={700}
                            images={images}
                            showBullets={true}
                            showNavs={true}
                        />
                    </div>
                    <div className='col-md-3'>

                        <Card bg={'primary'} text={'white'} style={{ width: '27rem' }} className='mt-3 mb-2'>
                                <Card.Header as="h4">નોટિસ</Card.Header>
                            <ListGroup className="list-group-flush">
                                {notice.map(item=>(
                                    <ListGroup.Item key={item._id}>{item.notice}</ListGroup.Item>
                                ))}
                            </ListGroup>
                        </Card>

                        <Card bg={'success'} text={'white'} style={{ width: '27rem' }} className='mt-3 mb-2'>
                                <Card.Header as="h4">લેટરપેડ</Card.Header>
                            <ListGroup className="list-group-flush">
                            {letterpad.map(item=>(
                                    <ListGroup.Item key={item._id}>{item.letter}</ListGroup.Item>
                                ))}
                            </ListGroup>
                        </Card>
                        
                       
                        

                    </div>
                </div>
            </section>


            <About></About>
        </div>
    )
}



export default Home
