import React, { useState } from 'react'
import './Parentforms.css'
import { Button, Form, Row, Col } from 'react-bootstrap';
import axios from 'axios';

const Parentforms = ({ history }) => {
    const [Name, setName] = useState("");
    const [mobilNo, setMobileNo] = useState("");
    const [email, setEmail] = useState("");
    const [dateofbirth, setDateofbirth] = useState("");
    const [university, setUniversity] = useState("");
    const [profession, setProfession] = useState("");
    const [company_name, setComapny_name] = useState("");
    const [address, setAddress] = useState("");
    const [pincode,setPincode] = useState("");

    const onSubmitHandler = async (e) => {
        e.preventDefault();
        try{
            const data = {
                 Name,
                 mobilNo,
                 email,
                 dateofbirth,
                 university,
                 profession,
                 company_name,
                 address,
                 pincode,
            };
            await axios.post("https://api.ppag.co.in/form",data);
            history.push("/");
            console.log(data)
        }
        catch (error){
            console.log(error.message);
        }
    }

    return (

        <div className="container">
            <div>
                <img className='main-image' src='./images/name.png' alt='PPAG' style={{width:"100%"}}/>
            </div>

            <div className="heading">
                <h1>
                    પીપીએજીના આજીવન સભ્ય થવા માટેનું અરજી ફોર્મ
                </h1>
                <div className='formsss'>
                    <h3>
                        પ્રતિ
                    </h3>
                    <h3>પ્રમુખ/મંત્રીશ્રી,</h3>
                    <h5 style={{ color: "#000" }}>
                        સભ્યપદ માટે કૃપા કરીને નીચેની વિગતો પૂર્ણ કરો.
                    </h5>
                </div>
                <Form>
                    <Form.Group className=" text" controlId="name">
                        <Form.Label>અરજદારનું નામ :</Form.Label>
                        <Form.Control 
                        type='text'
                        placeholder="અરજદારનું નામ "
                        value={Name}
                        onChange={(e)=> setName(e.target.value)}
                         />
                    </Form.Group>
                    <Row className="mb-3 text">
                        <Form.Group as={Col} controlId="number">
                            <Form.Label>મોબાઇલ નંબર</Form.Label>
                            <Form.Control 
                            type="text" 
                            placeholder="મોબાઇલ નંબર" 
                            maxLength={10}
                            value={mobilNo}
                            onChange={(e)=> setMobileNo(e.target.value)}
                             />
                        </Form.Group>

                        <Form.Group as={Col} controlId="formGridPassword">
                            <Form.Label>ઇ-મેલ</Form.Label>
                            <Form.Control 
                            type="email" 
                            placeholder="ઇ-મેલ" 
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            />
                        </Form.Group>
                    </Row>
                    <Row className="mb-3 text">
                        <Form.Group as={Col} controlId="formGriddate">
                            <Form.Label>જન્મ તારીખ</Form.Label>
                            <Form.Control 
                            type="date" 
                            placeholder="જન્મ તારીખ" 
                            value={dateofbirth}
                            onChange={(e) => setDateofbirth(e.target.value)}
                            />
                        </Form.Group>

                        <Form.Group as={Col} controlId="formGridPassword">
                            <Form.Label>અભ્યાસ/લાયકાત</Form.Label>
                            <Form.Control 
                            type="Text" 
                            placeholder="અભ્યાસ/લાયકાત"
                            value={university}
                            onChange={(e) => setUniversity(e.target.value)}
                             />
                        </Form.Group>
                    </Row>
                    <Row className="mb-3 text">
                        <Form.Group as={Col} controlId="formGriddate">
                            <Form.Label>વ્યવસાય</Form.Label>
                            <Form.Control 
                            type="text" 
                            placeholder="વ્યવસાય"
                            value={profession}
                            onChange={(e) => setProfession(e.target.value)}
                             />
                        </Form.Group>

                        <Form.Group as={Col} controlId="formGridPassword">
                            <Form.Label>જો નોકરી કરતા હોય તે સંસ્થાનું નામ</Form.Label>
                            <Form.Control 
                            type="Text" 
                            placeholder="જો નોકરી કરતા હોય તે સંસ્થાનું નામ"
                            value={company_name}
                            onChange={(e) => setComapny_name(e.target.value)}
                            />
                        </Form.Group>
                    </Row>
                    
                    <Row className="mb-3 text">
                        <Form.Group as={Col} controlId="formGriddate">
                            <Form.Label>પત્ર વ્યવહારનું કાયમી સરનામું</Form.Label>
                            <Form.Control 
                            type="text" 
                            placeholder="પત્ર વ્યવહારનું કાયમી સરનામું "
                            value={address}
                            onChange={(e) => setAddress(e.target.value)}
                            />
                        </Form.Group>

                        <Form.Group as={Col} controlId="formGridPassword">
                            <Form.Label>પિન કોડ </Form.Label>
                            <Form.Control 
                            type="Text" 
                            placeholder="પિન કોડ" 
                            value={pincode}
                            onChange={(e) => setPincode(e.target.value)}
                            />
                        </Form.Group>
                    </Row>
                    <h5 style={{ color: "#000" }}>
                    નોંધ: પીપીએજી કારોબારી/ હોદ્દેદારો દ્વારા વખતોવખત જે નિર્ણયો/ઠરાવો/નિયમો/પરિપત્રો કરવામાં આવશે તેનું પાલન કરવા માટે હું બંધાઉ છું. જો હું નિયમોનું પાલન ના કરું તો હોદ્દેદારો જે નિર્ણય કરશે તે મને બંધનકર્તા રહેશે તેની ખાત્રી આપુ છું.  
                    </h5>
                    <h5 style={{ color: "#000" }}>**NEFT માટે ખાતા ધારકનું નામ: પ્લાન્ટ પ્રોટેક્શન એસોસીએશન ઓફ ગુજરાત, ખાતા નં. ૩૨૩૩૮૪૪૪૬૧, સેન્ટ્રલ બેન્ક ઓફ ઇન્ડીયા, ઇન્સ્ટીટયુટ ઓફ એગ્રીકલ્ચર, આણંદ; IFSC Code : CBIN0281262 ખાતે ટ્રાન્સફર કર્યા બાદ રસીદ નીચે જણાવેલ મોબાઇલ નં. પર ફોટો પાડીને મોકલી આપવી.   </h5>
                    <Form.Group className="mb-3 text">
                        <Form.Label>સભ્ય ફી : રૂ. ૧૦૦૦/- (અંકે રૂપિયા એક હજાર પૂરા) (રોકડા/NEFT**)  </Form.Label>
                    </Form.Group>
                    <Button 
                    type="submit" 
                    variant='primary'
                    onClick={onSubmitHandler}
                    style={{backgroundColor: "black" , borderColor: "black"}}>Submit</Button>
                </Form>
                <div className='formsss'>
                    <h4 style={{ color: "#000"}}>
                    <u>પત્ર વ્યવહારનું સરનામું:  </u> </h4>
                    <h3>(૧) ડૉ.એન.બી.પટેલ, ખજાનચી-પીપીએજી, જૈવિક નિયંત્રણ પ્રયોગશાળા, આણંદ કૃષિ યુનિવર્સિટી, આણંદ-૩૮૮ ૧૧૦, મો. ૯૯૯૮૯ ૬૦૫૨૫, E-mail : nainesh@aau.in</h3>
                    <h3>(૨) ડૉ.બી.એચ.પટેલ, મહામંત્રી-પીપીએજી, બી-૯, ઉર્મી સોસાયટી, જાગનાથ મહાદેવ પાસે, આણંદ – ૩૮૮ ૦૦૧, મો. ૯૯૨૫૫ ૨૦૮૫૧, E-mail: bhp.ppaganand@yahoo.com, drbabulalpatel2015@gmail.com</h3>
                </div>
            </div>
        </div>
    )
}

export default Parentforms