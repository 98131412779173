import React , {useEffect,useState} from 'react'
import './Navbar.css'
import { NavLink } from 'react-router-dom'
import $ from 'jquery';


const Navbar = () => {

    function animation(){

        var tabsNewAnim = $('#navbarSupportedContent');
        var activeItemNewAnim = tabsNewAnim.find('.active');
        var activeWidthNewAnimHeight = activeItemNewAnim.innerHeight();
        var activeWidthNewAnimWidth = activeItemNewAnim.innerWidth();
        var itemPosNewAnimTop = activeItemNewAnim.position();
        var itemPosNewAnimleft = activeItemNewAnim.position();
        $ (".hori-selector").css({
            "top":itemPosNewAnimTop.top + "px",
            "left":itemPosNewAnimleft.left + "px",
            "height":activeWidthNewAnimHeight + "px",
            "width":activeWidthNewAnimWidth + "px",
        });
        $("#navbarSupportedContent").on("click","li",function(e){
            $('#navbarSupportedContent ul li').removeClass("active");
            $(this).addClass('active');
            var activeWidthNewAnimHeight = $(this).innerHeight();
            var activeWidthNewAnimWidth = $(this).innerWidth();
            var itemPosNewAnimTop = $(this).position();
            var itemPosNewAnimLeft = $(this).position();
            $(".hori-selector").css({
                "top":itemPosNewAnimTop.top + "px",
                "left":itemPosNewAnimLeft.left + "px",
                "height":activeWidthNewAnimHeight + "px",
                "width":activeWidthNewAnimWidth + "px",
            })
        })
    }
        const [windowWidth, setWindowWidth] = useState(window.innerWidth);
        const [windowHeight, setWindowHeight] = useState(window.innerHeight);
      
        useEffect(() => {
          function handleResize() {
            setWindowWidth(window.innerWidth);
            setWindowHeight(window.innerHeight);
          }
      
          window.addEventListener('resize', handleResize);
          return () => {
            window.removeEventListener('resize', handleResize);
          };
        }, []);

    useEffect(() => {
        
        animation();
        $(window).on('resize', function(){
            setTimeout(function() { animation
            (); }, 500);
        });

    }, []);


    return (
        <div>
        <nav className="navbar navbar-expand-lg navbar-mainbg">
            <div style={{'color':'#9ac2f9', 'fontSize':'1.5rem','marginLeft':'20px'}}>પ્લાન્ટ પ્રોટેક્શન એસોસીએશન ઓફ ગુજરાત</div>
        <button
            className="navbar-toggler"
            onClick={ function(){
                setTimeout(function(){
                    animation(); 
                });
            }}
            type="button"
            data-toggle="collapse"
            data-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation">
            <i className="fas fa-bars text-white"></i>
        </button>  
        <div
            className="collapse navbar-collapse"
            id="navbarSupportedContent">
            <ul className="navbar-nav ms-auto ">
                <div className="hori-selector">
                    <div className="left"></div>
                    <div className="right"></div>
                </div>
                <li className="nav-item active">
                    <NavLink className="nav-link" to="/" exact>
                        <i className="fas fa-home">
                        </i>હોમ    
                    </NavLink>
                </li>
                
                

                <li className="nav-item">
                    <NavLink className="nav-link" to="/Karobari" exact>
                        <i className="fas fa-user-friends">
                        </i>કેન્દ્રીય સભ્યો 
                    </NavLink>
                </li>

                <li className="nav-item">
                    <NavLink className="nav-link" to="/Sabhyo" exact>
                        <i className="fas fa-users">
                        </i>આજીવન સભ્યો 
                    </NavLink>
                </li>
                <li className="nav-item">
                    <NavLink className="nav-link" to="/Seminar" exact>
                        <i className="fas fa-comment">
                        </i>પરિસંવાદ
                    </NavLink>
                </li>
                <li className="nav-item">
                    <NavLink className="nav-link" to="/Meeting" exact>
                        <i className="fas fa-handshake">
                        </i>મીટીંગ
                    </NavLink>
                </li>

                <li className="nav-item">
                    <NavLink className="nav-link" to="/Award" exact>
                        <i className="fas fa-medal">
                        </i>એવોર્ડ 
                    </NavLink>
                </li>
                <li className="nav-item">
                    <NavLink className="nav-link" to="/Gallery" exact>
                        <i className="fas fa-image">
                        </i>ગેલેરી
                    </NavLink>
                </li>

                <li className="nav-item">
                    <NavLink className="nav-link" to="/Contact" exact>
                        <i className="fas fa-address-book">
                        </i>સંપર્ક કરો
                    </NavLink>
                </li>
            </ul>
        </div>
        </nav>    
        </div>
    )
}

export default Navbar
