import React, { useEffect, useState } from 'react'
import ppagData from './ppag.json'
import axios from 'axios';

import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table'
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css'
import './award.css'
const Award =() =>{
	const [award,setaward] = useState([]);
	
	const loadaward = async () =>{
		try {
			const response = await axios.get("http://api.ppag.co.in/Award");
			console.log(response.data);
			setaward(response.data)
		} catch (error) {
			console.log(error.message)
		}
	} 
	useEffect(() => loadaward(),[])


	const DisplayData2=ppagData.map(
		(info)=>{
			return(
                
				<Tr>
                   <Td>{info.ids}</Td>
					<Td style={{'textAlign':'left'}}>{info.awardname}</Td>
					<Td style={{'textAlign':'left'}}>{info.year2017}</Td>
					<Td style={{'textAlign':'left'}}>{info.year2018}</Td>
					<Td style={{'textAlign':'left'}}>{info.year2019}</Td>
					<Td style={{'textAlign':'left'}}>{info.year2020}</Td>

				</Tr>
				
			)
            
		}
	)

	return(
		<div  style={{textAlign:"center", width:"98%"}}>
			<h2>વિવિધ એવોર્ડ માટે મળેલ અનુદાન</h2>
			<Table>
				<Thead>
					<Tr>
					<Th>ક્રમ</Th>
                    <Th style={{'textAlign':'left'}}>નામ</Th>
					<Th style={{'textAlign':'left'}}>અનુદાનની રકમ(રૂ.)</Th>
					<Th style={{'textAlign':'left'}}>વર્ષ</Th>
					<Th style={{'textAlign':'left'}}>એવોર્ડ</Th>
					</Tr>
				</Thead>
				<Tbody>
				{award.map(item =>(
						<Tr key={item._id}>
							<Td>{item.No}</Td>
							<Td style={{'textAlign':'left'}}>{item.Name}</Td>
							<Td style={{'textAlign':'left'}}>{item.amount}</Td>
							<Td style={{'textAlign':'left'}}>{item.Year}</Td>
							<Td style={{'textAlign':'left'}}>{item.Award}</Td>
						</Tr>
					))}
				</Tbody>
			</Table>
			<h2>પીપીએજી દ્વારા એવોર્ડ મેળવનારની યાદી</h2>
			<Table>
				<Thead>
					<Tr>
                    <Th>અ.નં.</Th>
					<Th style={{'textAlign':'left'}}>એવોર્ડનું નામ</Th>
					<Th style={{'textAlign':'left'}}>વર્ષ ૨૦૧૭</Th>
					<Th style={{'textAlign':'left'}}>વર્ષ ૨૦૧૮</Th>
					<Th style={{'textAlign':'left'}}>વર્ષ ૨૦૧૯</Th>
					<Th style={{'textAlign':'left'}}>વર્ષ ૨૦૨૦</Th>
					
					</Tr>
				</Thead>
				<Tbody>
				
					
					{DisplayData2}
					
					
				</Tbody>
			</Table>
			
			
		</div>
	)
    
}

export default Award;




