import React, { useState } from "react";
import axios from "axios"
import { useHistory } from "react-router-dom";

const Admin = () => {

    const [email, setemail] = useState("");
    const [password, setPassword] = useState("");
    let history = useHistory();
    
    const onSubmitHandler = async (e) => {
        e.preventDefault();
        
        try {
            const data = {
              email,
              password,
            };
            await axios.post("https://api.ppag.co.in/login", data);
            alert("login Successfull")
            history.push("/adminform");
          } catch (error) {
            console.log(error.message);
            alert('invelid Credentials');
          }
        };

    return (
        <div className="container">
            <h1>ADMIN SIGNIN</h1>
            <input
            value={email}
            onChange={(e) => setemail(e.target.value)}
            placeholder='email' className="input-admin" type="text" />

            <input
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            placeholder='Password' className="input-admin"  />

            <button
            onClick={onSubmitHandler}
            className='submit-btn'> SUBMIT </button>

        </div>
    )
}

export default Admin