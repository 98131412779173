import React, { useEffect, useState } from 'react'
import './karobars.css'
import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table'
import { Card } from 'react-bootstrap';
import axios from 'axios'



//datas
const officers = [
  { image: './images/k1.jpg', title: "ડૉ. જે.જી.પટેલ", description: "પ્રમુખ", },
  { image: './images/k2.jpg', title: "ડૉ. આર.સી.ઝાલા", description: "ઉપપ્રમુખ", },
  { image: './images/k3.jpg', title: "ડૉ. બી.એચ.પટેલ", description: "મહામંત્રી", },
  { image: './images/k4.jpg', title: "ડૉ. એન.બી.પટેલ", description: "ખજાનચી", },
];

// for officer
const renderCard = (card, index) => {
  return (
    <Card style={{ width: '18rem' }} key={index} className="box">
      <Card.Img varient="top" src={card.image} className="image" />
      <Card.Body>
        <Card.Title className="title">{card.title}</Card.Title>
        <Card.Text className="description">{card.description}</Card.Text>
      </Card.Body>
    </Card>
  )
}

const Karobars = () => {
  const [zonal,setzonal] = useState([]);
  const [karobari,setkarobari] = useState([]);
  const [petron,setpetron] = useState([]);
  const [shalakar,setshalakar] = useState([]);
  const [zonal1,setzonal1] = useState([]);
  const [hodedaro,sethodedaro] = useState([]);

	const loadzonal = async () =>{
		try {
			const response = await axios.get("https://api.ppag.co.in/Zonal");
			console.log(response.data);
			setzonal(response.data)
		} catch (error) {
			console.log(error.message)
		}
	} 
  const loadkarbari = async () =>{
		try {
			const response = await axios.get("https://api.ppag.co.in/Karobari");
			console.log(response.data);
			setkarobari(response.data)
		} catch (error) {
			console.log(error.message)
		}
	} 
  const loadpetron = async () =>{
		try {
			const response = await axios.get("https://api.ppag.co.in/petron");
			console.log(response.data);
			setpetron(response.data)
		} catch (error) {
			console.log(error.message)
		}
	} 
  const loadshalakar = async () =>{
		try {
			const response = await axios.get("https://api.ppag.co.in/Shalakar");
			console.log(response.data);
			setshalakar(response.data)
		} catch (error) {
			console.log(error.message)
		}
	} 
	const loadzonal1 = async () =>{
		try {
			const response = await axios.get("https://api.ppag.co.in/Zonal1");
			console.log(response.data);
			setzonal1(response.data)
		} catch (error) {
			console.log(error.message)
		}
	} 
  const loadhodedaro = async () =>{
		try {
			const response = await axios.get("https://api.ppag.co.in/Hodedaro");
			console.log(response.data);
			sethodedaro(response.data)
		} catch (error) {
			console.log(error.message)
		}
	}

	useEffect(() => loadzonal(),[])
  useEffect(() => loadkarbari(),[])
  useEffect(()=> loadpetron(),[])
  useEffect(() => loadshalakar(),[])
  useEffect(() => loadzonal1(),[])
  useEffect(() => loadhodedaro(),[])


    return (
      <div>
        <div className='container-fluid '>
          <h1 style={{'fontSize':60 ,'color':'#162655'}}>પ્લાન્ટ પ્રોટેક્શન એસોસીએશન ઓફ ગુજરાત (પીપીએજી)</h1>
          <h1 style={{'color':'#162655', 'fontSize':40}}>કેન્દ્રીય પદધારકો (વર્ષ: ૨૦૨૨ થી ૨૦૨૪ સુધી)</h1>
          </div>
        <div>
            <div className='container '>
              <h1 className='class-title'style={{'color':'#d1232a'}}>કેન્દ્રીય કારોબારીના હોદ્દેદારો</h1>
              <div className="grid">
                {officers.map(renderCard)}
              </div>
            </div>
            <div className='container-fluid'>
              <h1 className='class-title'style={{'color':'#d1232a'}}>ઝોનલ અને સહ ઝોનલ કો-ઓર્ડીનેટર</h1>
              <div style={{textAlign:"center", width:"98%"}}>
              <Table>
                <Thead>
                  <Tr>
                    <Th>ક્રમ </Th>
                    <Th style={{'textAlign':'left'}}>ઝોનલ કો-ઓર્ડીનેટર </Th>
                    <Th style={{'textAlign':'left'}}>વિભાગ </Th>
                    <Th style={{'textAlign':'left'}}>સહ ઝોનલ કો-ઓર્ડીનેટર</Th>
                    <Th style={{'textAlign':'left'}}>યુનિવર્સિટી </Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {zonal.map(item =>(
                    <Tr key={item._id}>
                    <Td>{item.No}</Td>
                    <Td style={{'textAlign':'left'}}>{item.co}</Td>
                    <Td style={{'textAlign':'left'}}>{item.subject}</Td>
                    <Td style={{'textAlign':'left'}}>{item.sahco}</Td>
                    <Td style={{'textAlign':'left'}}>{item.university}</Td>
                  </Tr>
                  ))}
                </Tbody>
              </Table>
              </div>
              <h1 className='class-title'style={{'color':'#d1232a'}}>કારોબારીના સભ્યો</h1>
              <div style={{textAlign:"center", width:"98%"}}>
              <Table>
                <Thead>
                <Tr>
                    <Th>ક્રમ </Th>
                    <Th style={{'textAlign':'left'}}>નામ</Th>
                    <Th style={{'textAlign':'left'}}>હોદ્દો</Th>
                  </Tr>
                </Thead>
                <Tbody>
                {karobari.map(item =>(
                    <Tr key={item._id}>
                    <Td>{item.No}</Td>
                    <Td style={{'textAlign':'left'}}>{item.Name}</Td>
                    <Td style={{'textAlign':'left'}}>{item.subject}</Td>
                  </Tr>
                  ))}
                </Tbody>
              </Table>
              </div>
              <h1 className='class-title'style={{'color':'#d1232a'}}>પેટ્રોન સભ્યો</h1>
              <div style={{textAlign:"center", width:"98%"}}>
              <Table>
                <Thead>
                <Tr>
                    <Th>ક્રમ</Th>
                    <Th style={{'textAlign':'left'}}>નામ</Th>
                    <Th style={{'textAlign':'left'}}>સ્થળ</Th>
                  </Tr>
                </Thead>
                <Tbody>
                {petron.map(item =>(
                    <Tr key={item._id}>
                    <Td>{item.No}</Td>
                    <Td style={{'textAlign':'left'}}>{item.Name}</Td>
                    <Td style={{'textAlign':'left'}}>{item.Place}</Td>
                  </Tr>
                  ))}
                </Tbody>
              </Table>
              </div>
              <h1 className='class-title'style={{'color':'#d1232a'}}>સલાહકાર સભ્યો</h1>
              <div style={{textAlign:"center", width:"98%"}}>
              <Table>
                <Thead>
                <Tr>
                    <Th>ક્રમ </Th>
                    <Th style={{'textAlign':'left'}}>નામ </Th>
                    <Th style={{'textAlign':'left'}}>ઝોન</Th>
                  </Tr>
                </Thead>
                <Tbody>
                {shalakar.map(item =>(
                    <Tr key={item._id}>
                    <Td>{item.No}</Td>
                    <Td style={{'textAlign':'left'}}>{item.Name}</Td>
                    <Td style={{'textAlign':'left'}}>{item.Place}</Td>
                  </Tr>
                  ))}
                </Tbody>
              </Table>
              </div>
              <h1 className='class-title'style={{'color':'#d1232a'}}>પીપીએજી ના હોદ્દેદારો</h1>
              <div style={{textAlign:"center", width:"98%"}}>
              <Table>
                <Thead>
                <Tr>
                    <Th style={{'textAlign':'left'}}>હોદ્દો</Th>
                    <Th style={{'textAlign':'left'}}>નામ</Th>
                    <Th style={{'textAlign':'left'}}>વર્ષ</Th>
                  </Tr>
                </Thead>
                <Tbody>
                {hodedaro.map(item =>(
                    <Tr key={item._id}>
                    <Td style={{'textAlign':'left'}}>{item.position}</Td>
                    <Td style={{'textAlign':'left'}}>{item.Name}</Td>
                    <Td style={{'textAlign':'left'}}>{item.Year}</Td>
                  </Tr>
                  ))}
                </Tbody>
              </Table>
              </div>
              <h1 className='class-title'style={{'color':'#d1232a'}}>પીપીએજીના ઝોનલ કક્ષાના પદધારકો </h1>
              <div style={{textAlign:"center", width:"98%"}}>
              <Table>
                <Thead>
                <Tr>
                    <Th style={{'textAlign':'left'}}>સ્થળ </Th>
                    <Th style={{'textAlign':'left'}}>કો-ઓર્ડીનેટર</Th>
                    <Th style={{'textAlign':'left'}}>વર્ષ</Th>
                  </Tr>
                </Thead>
                <Tbody>
                {zonal1.map(item =>(
                    <Tr key={item._id}>
                    <Td style={{'textAlign':'left'}}>{item.Place}</Td>
                    <Td style={{'textAlign':'left'}}>{item.Name}</Td>
                    <Td style={{'textAlign':'left'}}>{item.Year}</Td>
                  </Tr>
                  ))}
                </Tbody>
              </Table>
              </div>
            </div>
        </div>
      </div>
    )
  }

export default Karobars
