import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table'
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css'
import './Sabhyo.css'

const Sabhyo = () =>{
	const [aajivan,setaajivan] = useState([]);

	const loadaajivan = async () =>{
		try {
			const response = await axios.get("https://api.ppag.co.in/Sabhyo");
			console.log(response.data);
			setaajivan(response.data)
		} catch (error) {
			console.log(error.message)
		}
	} 
	useEffect(() => loadaajivan(),[])

	return(
		<div  style={{textAlign:"center", width:"98%"}}>
			<h2>આજીવન સભ્યો</h2>
			<Table>
				<Thead>
					<Tr>
					<Th>ક્રમ </Th>
					<Th style={{'textAlign':'left'}}>નામ </Th>
					<Th style={{'textAlign':'left'}}>સરનામું </Th>
					<Th style={{'textAlign':'left'}}>ઈ-મેઈલ </Th>
					<Th style={{'textAlign':'left'}}>મોબાઈલ નંબર </Th>
					</Tr>
				</Thead>
				<Tbody>
					{aajivan.map(item =>(
						<Tr key={item._id}>
							<Td>{item.No}</Td>
							<Td style={{'textAlign':'left'}}>{item.Name}</Td>
							<Td style={{'textAlign':'left'}}>{item.address}</Td>
							<Td style={{'textAlign':'left'}}>{item.email}</Td>
							<Td style={{'textAlign':'left'}}>{item.mobileNo}</Td>
						</Tr>
					))}
				</Tbody>
			</Table>
		</div>
	)
}

export default Sabhyo;




