import React from 'react'
import './Gallery.css'

const Gallery = () => {
    return (
        <div className="container">
            <div className="row">
                <div className="column">
                    <img src="./images/1.jpg"  alt=""/>
                    <img src="./images/2.jpg"  alt=""/>
                    <img src="./images/3.jpg"  alt=""/>
                    <img src="./images/4.jpg"  alt=""/>
                    <img src="./images/5.jpg"  alt=""/>
                    <img src="./images/6.jpg"  alt=""/>
                    <img src="./images/7.jpg"  alt=""/>
                </div>
                <div className="column">
                    <img src="./images/8.jpg"  alt=""/>
                    <img src="./images/9.jpg"  alt=""/>
                    <img src="./images/10.jpg"  alt=""/>
                    <img src="./images/11.jpg"  alt=""/>
                    <img src="./images/12.jpg"  alt=""/>
                    <img src="./images/13.jpg"  alt=""/>
                    <img src="./images/14.jpg"  alt=""/>
                    


                </div>
                <div className="column">
                    <img src="./images/16.jpg"  alt=""/>
                    <img src="./images/17.jpg"  alt=""/>
                    <img src="./images/15.jpg"  alt=""/>
                    <img src="./images/1.jpg"  alt=""/>
                    <img src="./images/2.jpg"  alt=""/>
                    <img src="./images/3.jpg"  alt=""/>
                    <img src="./images/4.jpg"  alt=""/>
                    
                </div>
                <div className="column">
                    <img src="./images/5.jpg"  alt=""/>
                    <img src="./images/6.jpg"  alt=""/>
                    <img src="./images/7.jpg"  alt=""/>
                    <img src="./images/8.jpg"  alt=""/>
                    <img src="./images/9.jpg"  alt=""/>
                    <img src="./images/10.jpg"  alt=""/>
                    <img src="./images/11.jpg"  alt=""/>
                    
                </div>
            </div>
        </div>        
    )
}

export default Gallery
