import React from "react";
import './award.css'
import Award from './Award'


const Card = () =>{
    return(
        <div  style={{textAlign:"center"}}>
            <h2>એવોર્ડ આપનાર દાતાશ્રીઓ</h2>
            <div className="container-fluid">
            <div className="row">
                <div className="col-sm">
                    <div className="Card">
                        <div className="upperContainer">
                            <div className="imageContainer">
                                <img className="image" src="./images/gau.jpeg" alt=" " height="100px" width="100px" />
                            </div>
                        </div>
                        <div className="lowerContainer">
                            <h3>ડૉ. મધુસુદન વલ્લભભાઈ દેસાઈ</h3>
                            <h4>રીટાયાર્ડ  કેમ્પસ ડાયરેક્ટર, યુએસએ</h4>
                            <p>ક્ષેત્રમાં છોડ સંરક્ષણ વૈજ્ઞાનિકોની જીવનકાળની સિદ્ધિઓ</p>
                            <button>રકમ:₹100000</button>
                        </div>
                    </div>
                </div>
                <div className="col-sm">
                    <div className="Card">
                        <div className="upperContainer">
                            <div className="imageContainer">
                                <img className="image" src="./images/aau.png" alt=" " height="100px" width="100px" />
                            </div>
                        </div>
                        <div className="lowerContainer">
                            <h3>શ્રી મુકુંદભાઈ વી. પટેલ</h3>
                            <h4>રીટાયાર્ડ  એક્સ્ટેંશન પેથોલોજીસ્ટ, USA</h4>
                            <p>શ્રેષ્ઠ ખેડૂત સારી ગુણવત્તા સાથે ઉચ્ચ પાક ઉત્પાદન માટે છોડ સંરક્ષણ તકનીક અપનાવે છે</p>
                            <button>રકમ:₹132000</button>
                        </div>
                    </div>
                </div>
                <div className="col-sm">
                    <div className="Card">
                        <div className="upperContainer">
                            <div className="imageContainer">
                                <img className="image" src="./images/upl.jpg" alt=" " height="100px" width="100px" />
                            </div>
                        </div>
                        <div className="lowerContainer">
                            <h3>યુપીએલ લિમિટેડ</h3>
                            <h4>મુંબઈ</h4>
                            <p>ખેડૂતોને છોડ સંરક્ષણ તકનીકોનું અસરકારક ટ્રાન્સફર (શ્રેષ્ઠ વિસ્તરણ પ્રવૃત્તિઓ માટે)</p>
                            <button>રકમ:₹100000</button>
                        </div>
                    </div>
                </div>
                <div className="col-sm">
                    <div className="Card">
                        <div className="upperContainer">
                            <div className="imageContainer">
                                <img className="image" src="./images/agriland.webp" alt=" " height="100px" width="100px" />
                            </div>
                        </div>
                        <div className="lowerContainer">
                            <h3>એગ્રીલેન્ડ બાયોટેક લિમિટેડ</h3>
                            <h4>વડોદરા, ગુજરાત</h4>
                            <p>શ્રેષ્ઠ ખેડૂત સારી ગુણવત્તા સાથે ઉચ્ચ પાક ઉત્પાદન માટે છોડ સંરક્ષણ તકનીક અપનાવે છે</p>
                            <button>રકમ:₹100000</button>
                        </div>
                    </div>
                </div>
                <div className="col-sm">
                    <div className="Card">
                        <div className="upperContainer">
                            <div className="imageContainer">
                                <img className="image" src="./images/aspee.png" alt=" " height="100px" width="100px" />
                            </div>
                        </div>
                        <div className="lowerContainer">
                            <h3>ASPEE કૃષિ સંશોધન અને વિકાસ ફાઉન્ડેશન</h3>
                            <h4>મુંબઈ, મહારાષ્ટ્ર</h4>
                            <p>M.Sc દ્વારા કરવામાં આવેલ શ્રેષ્ઠ સંશોધન કાર્ય પ્લાન્ટ પ્રોટેક્શનમાં વિદ્યાર્થી</p>
                            <button>રકમ:₹100000</button>
                        </div>
                    </div>
                </div>
                <div className="col-sm">
                    <div className="Card">
                        <div className="upperContainer">
                            <div className="imageContainer">
                                <img className="image" src="./images/upl.jpg" alt=" " height="100px" width="100px" />
                            </div>
                        </div>
                        <div className="lowerContainer">
                            <h3>યુપીએલ લિમિટેડ</h3>
                            <h4>મુંબઈ</h4>
                            <p>છોડના રક્ષણ માટે નવી જંતુનાશક રચનાની નવીનતા</p>
                            <button>રકમ:₹100000</button>
                        </div>
                    </div>
                </div>
                <div className="col-sm">
                    <div className="Card">
                        <div className="upperContainer">
                            <div className="imageContainer">
                                <img className="image" src="./images/anand.png" alt=" " height="100px" width="100px" />
                            </div>
                        </div>
                        <div className="lowerContainer">
                            <h3>ડો. આર.સી.પટેલ</h3>
                            <h4>રીટાયાર્ડ  પ્રોફેસર અને હેડ, કીટવિજ્ઞાન વિભાગ, AAU, આણંદ</h4>
                            <p>શ્રેષ્ઠ સંશોધન કાર્ય પીએચ.ડી. પ્લાન્ટ પ્રોટેક્શનમાં વિદ્યાર્થી</p>
                            <button>રકમ:₹100000</button>
                        </div>
                    </div>
                </div>
                <div className="col-sm">
                    <div className="Card">
                        <div className="upperContainer">
                            <div className="imageContainer">
                                <img className="image" src="./images/avani.png" alt=" " height="100px" width="100px" />
                            </div>
                        </div>
                        <div className="lowerContainer">
                            <h3>અવની સીડ્સ લિમિટેડ</h3>
                            <h4>અમદાવાદ</h4>
                            <p>શ્રેષ્ઠ ખેડૂત સારી ગુણવત્તા સાથે ઉચ્ચ પાક ઉત્પાદન માટે છોડ સંરક્ષણ તકનીક અપનાવે છે</p>
                            <button>રકમ:₹100000</button>
                        </div>
                    </div>
                </div>
                <div className="col-sm">
                    <div className="Card">
                        <div className="upperContainer">
                            <div className="imageContainer">
                                <img className="image" src="./images/aau.png" alt=" " height="100px" width="100px" />
                            </div>
                        </div>
                        <div className="lowerContainer">
                            <h3>શ્રી મુકુંદભાઈ વી. પટેલ</h3>
                            <h4>રીટાયાર્ડ  એક્સ્ટેંશન પેથોલોજીસ્ટ, USA</h4>
                            <p>શ્રેષ્ઠ ખેડૂત સારી ગુણવત્તા સાથે ઉચ્ચ પાક ઉત્પાદન માટે છોડ સંરક્ષણ તકનીક અપનાવે છે</p>
                            <button>રકમ:₹100000</button>
                        </div>
                    </div>
                </div>
            </div>
            </div>
            <Award></Award>
        </div>
    )
}



export default Card