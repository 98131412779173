import React from 'react'
import './Footer.css'

const Footer = () => {
    return (
        <div className="container-fluid foot">
            <hr className="default" />
            <div className="row mg row-footer">
                <div className="col-4 offset-1 links text-center">
                    <img src='../logo.png' alt='developer logo' style={{ maxWidth: "inherit" }}></img>
                </div>
                <div className="col-3 address offset-1">
                    <address>
                        કીટકશાસ્ત્ર વિભાગ<br />
                        બં.અ. કૃષિ મહાવિદ્યાલય<br />
                        આણંદ કૃષિ યુનિવર્સિટી,<br />
                        આણંદ-૩૮૮ ૧૧૦ <br />
                        ગુજરાત <br />
                        <i class="fa fa-phone fa-lg"></i>  +91 992 552 0851<br />
                        <i class="fa fa-envelope fa-lg"></i> <a class="link" href="mailto:ppag.secretary@gmail.com">ppag.secretary@gmail.com</a>
                    </address>
                </div>
                <div className="col-3 align-self-center">
                    <div class="icon-circle blue">
                        <div class="icon">
                            <i class="fas fa-user-tie"></i> </div>
                        <h4 class="text-white">આજીવન સભ્ય થવા માટે</h4>
                        <p>ઓનલાઇન ફોર્મ</p>
                        <a href='./forms' class="login-trigger">ક્લિક કરો</a>
                    </div>
                </div>
            </div>
            <div className="row justify-content-center" style={{ marginTop: "20px" }}>
                <div className="col-auto">
                    <p class="copy">© Copyright 2022 Pandemonium Technologies</p>
                </div>
            </div>
        </div>
    )
}

export default Footer
