import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Redirect,
  Switch
} from 'react-router-dom';

import About from "./pages/About/About";
import Contact from "./pages/Contact/Contact";
import Home from "./pages/Home/Home";
import Gallery from "./pages/Gallery/Gallery"
import Navbar from "./Components/Navbar/Navbar";
import Footer from "./Components/Footer/Footer";
import Parentforms from "./pages/Home/Parentforms";
import Sabhyo from "./pages/Sabhyo/Sabhyo";
import Karobari from "./pages/Karobari/karobars";
import Seminar from "./pages/Seminar/Seminar";
import Meeting from "./pages/Meeting/meet";
import Award from "./pages/Award/Card"
import Admin from "./pages/admin/Admin";
import SignupForm from "./pages/admin/SignupForm";
import Adminform from "./pages/admin/Adminform";


const App = () => {
  return (
    <div>
      <Router>
        <Navbar className="nav" />
        <main>
          <Switch>
            <Route path='/' exact component={Home}>
            </Route>
            <Route path='/about' exact>
              <About />
            </Route>
            <Route path='/Gallery' exact>
              <Gallery />
            </Route>
            <Route path='/Contact' exact>
              <Contact />
            </Route>
            <Route path='/Sabhyo' exact>
              <Sabhyo />
            </Route>
            <Route path='/Karobari' exact>
              <Karobari />
            </Route>
            <Route path='/Seminar' exact>
              <Seminar />
            </Route>
            <Route path='/Meeting' exact>
              <Meeting />
            </Route>
            <Route path='/Award' exact>
              <Award />
            </Route>

            <Route path='/forms' exact component={Parentforms}>

            </Route>
            <Route path='/admin' exact>
              <Admin />
            </Route>
            <Route path='/adminform' exact component={Adminform}>

            </Route>

            <Route path='/SignupForm' exact>
              <SignupForm />
            </Route>
            <Redirect to="/" />
          </Switch>
        </main>
        <Footer />
      </Router>
    </div>
  );
}

export default App;
