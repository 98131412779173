import React from 'react'
import './Contact.css'
const Contact = () => {
    return (
    <section class="contact mt-3">
        <div class="container">
            <div class="section-title">
                <h2 class="texts">
                સંપર્ક 
                </h2>
                <p class="text-center">
                Department of Entomology, B.A. College of Agriculture, Anand Agricultural University, Anand-388 110<br/>
                કીટકશાસ્ત્ર વિભાગ બં.અ. કૃષિ મહાવિદ્યાલય આણંદ કૃષિ યુનિવર્સિટી, આણંદ-૩૮૮ ૧૧૦, ગુજરાત
                </p>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-6">
                <div class="row">
                    <div class="col-md-12">
                        <div class="icon-box">
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="icon-box mt-4">
                            <i class="fas fa-envelope"></i>
                            <h3>ઇ-મેઇલ</h3>
                            <p>ppag.secretary@gmail.com</p>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="icon-box mt-4">
                            <i class="fas fa-phone"></i>
                            <p>+91 992 552 0851</p>
                        </div><br />
                    </div>
                    
                    <div class="icon-box icon02">
                        </div>
                    </div>
            </div>
            <div class="col-lg-6">
                <form>
                    <div class="form-row">
                        <div class="col-md-6 form-group">
                            <input type="type"name="name"class="form-control"
                            id="name"placeholder=" નામ"/>
                        </div>
                        <div class="col-6 form-group">
                            <input type="email"name="email"class="form-control"
                            id="email"placeholder=" ઇ-મેઇલ"/>
                        </div>
                    </div>
                    <div class="form-group">
                        <input type="text" class="form-control"name="subject"
                        id="subject"placeholder="વિષય"/>
                    </div>
                    <div class="form-group">
                        <textarea class="form-control"name="message"row="6"
                        placeholder="સંદેશ"></textarea>
                    </div>
                    <div><a href="#" class="btn btn-about"> SUBMIT</a></div>
                </form>
            </div>
        </div>
    </section>    )
}

export default Contact
