import React from 'react'
import { Component } from 'react'
import JsonData from './Data.json'
import { Table, Tbody, Tr, Th, Td } from 'react-super-responsive-table'
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css'
import './meeting.css'



const anandData = JsonData.map(
    (info) => {
        return (
            <Tr>
                <Td><a href={info.karobarireport} target='_blank'>Download PDF</a></Td>
                <Td><a href={info.samanyareport} target='_blank'>Download PDF</a></Td>
            </Tr>
        )
    }
)

const navsariData = JsonData.map(
    (info) => {
        return (
            <Tr>
                <Td><a href={info.karobarireport} target='_blank'>Download PDF</a></Td>
                <Td><a href={info.samanyareport} target='_blank'>Download PDF</a></Td>
            </Tr>
        )
    }
)

const junagadhData = JsonData.map(
    (info) => {
        return (
            <Tr>
                <Td><a href={info.karobarireport} target='_blank'>Download PDF</a></Td>
                <Td><a href={info.samanyareport} target='_blank'>Download PDF</a></Td>
            </Tr>
        )
    }
)

const sardarData = JsonData.map(
    (info) => {
        return (
            <Tr>
                <Td><a href={info.karobarireport} target='_blank'>Download PDF</a></Td>
                <Td><a href={info.samanyareport} target='_blank'>Download PDF</a></Td>
            </Tr>
        )
    }
)



export class meet extends Component {
    constructor(props) {
        super(props)

        this.state = {
            tabType: 'anand'
        }
    }
    toggleMe = (value) => {
        this.setState({
            tabType: value
        })
    }

    render() {
        return (
            <div>
                <div className='container'>
                    <h1>પીપીએજી મીટીંગની કાર્યનોંધ </h1>
                    <button className="anand" onClick={() => this.toggleMe('anand')}>આણંદ </button>
                    <button className="navsari" onClick={() => this.toggleMe('navsari')}>નવસારી</button>
                    <button className="junagadh" onClick={() => this.toggleMe('junagadh')}>જૂનાગઢ </button>
                    <button className="sarder" onClick={() => this.toggleMe('sarder')}>સરદાર કૃષિનગર </button>
                </div>
                <div>
                    {
                        this.state.tabType === 'anand' &&
                        <div className='container-fluid'>
                            <h1 className='class-title'>આણંદ</h1>
                            <Table className='table'>
                                    <Tr>
                                        <Th>કારોબારી સભાનો અહેવાલ</Th>
                                        <Th>સામાન્ય સભાનો અહેવાલ </Th>
                                    </Tr>
                                <Tbody>
                                    {anandData}
                                </Tbody>
                            </Table>
                        </div>
                    }
                    {
                        this.state.tabType === 'navsari' &&
                        <div className='container-fluid'>
                            <h1 className='class-title'>નવસારી</h1>
                            <Table className='table'>
                                    <Tr>
                                        <Th>કારોબારી સભાનો અહેવાલ</Th>
                                        <Th>સામાન્ય સભાનો અહેવાલ </Th>
                                    </Tr>
                                <Tbody>
                                    {navsariData}
                                </Tbody>
                            </Table>
                        </div>
                    }
                    {
                        this.state.tabType === 'junagadh' &&
                        <div className='container-fluid'>
                            <h1 className='class-title'>જૂનાગઢ</h1>
                            <Table className='table'>
                                    <Tr>
                                        <Th>કારોબારી સભાનો અહેવાલ</Th>
                                        <Th>સામાન્ય સભાનો અહેવાલ </Th>
                                    </Tr>
                                <Tbody>
                                    {junagadhData}
                                </Tbody>
                            </Table>
                        </div>
                    }
                    {
                        this.state.tabType === 'sarder' &&
                        <div className='container-fluid'>
                            <h1 className='class-title'>સરદાર કૃષિનગર</h1>
                            <Table className='table'>
                                    <Tr className='tr'>
                                        <Th>કારોબારી સભાનો અહેવાલ</Th>
                                        <Th>સામાન્ય સભાનો અહેવાલ </Th>
                                    </Tr>
                                <Tbody>
                                    {sardarData}
                                </Tbody>
                            </Table>
                        </div>
                    }
                </div>
            </div>
        )
    }
}

export default meet